
import setUrl from "~/mixins/setUrl.js";
export default {
  layout: "child",
  components: {
    questionAvatar: () =>
      import("@/components/ask-question/questionAvatar.vue"),
    answerComment: () => import("@/components/ask-question/answerComment.vue"),
    newsRelease: () => import("@/components/ask-question/newsRelease.vue"),
    // photoGallery: () =>
    //   import("@modoc-component/src.components.photo-gallery/photo-gallery.vue"),
    questionGallery: () => import("@/components/community/gallery.vue"),
  },
  mixins: [setUrl],
  async asyncData({ params, store, query }) {
    const payloadParams = {
      id: params.id,
      qid: query.slug ? params.qid + `?share_by=${query.slug}` : params.qid,
    };
    try {
      const dataFetch = await store.dispatch(
        "public/getDiscussionDetail",
        payloadParams
      );
      return {
        dataFetch,
        serverID: params.id,
      };
    } catch (error) {}
  },
  data: () => ({
    loading: {
      detail: false,
      answer: false,
      topQuestion: false,
      release: false,
      join: false,
    },
    topQuestionLists: [],
    newsRelease: [],
    active_report: false,
    report_item: null,
    report_id: null,
    report_others_answer: "",
    dataFetch: {},
    report_questions: [
      {
        text: "Gambar",
        id: 1,
      },
      {
        text: "Spam",
        id: 2,
      },
      {
        text: "Offensive",
        id: 3,
      },
      {
        text: "Abuse",
        id: 4,
      },
      {
        text: "SARA",
        id: 5,
      },
    ],
    urlSlug: "",
    isActive: null,
    isMember: false,
    drawerMember: false,
    answers: {
      lists: [],
      offset: 1,
      limit: 5,
      total: 0,
    },
    isOwned: false,
    ads: {
      ads_1: {
        path: "/22728598014/Community-Below-My",
        size: [320, 250],
        dom_id: "div-gpt-ad-1662522760561-0",
      },
      ads_2: {
        path: "/22728598014/Community-Diskusi-Below",
        size: [320, 100],
        dom_id: "div-gpt-ad-1662522988075-0",
      },
    },
    ytId: "",
    communityID: null,
  }),
  watch: {
    "$store.state.auth.user.myProfile.id"() {
      this.setOwnedCommunity();
      this.fetchMember();
    },
  },
  beforeDestroy() {
    if (window.googletag) {
      window.googletag.destroySlots();
    }
  },
  created() {
    this.checkParams();
  },
  mounted() {
    this.checkURLParams();
    this.fetchCommunityData();
    this.fetchNewsRelease();
    this.fetchMember();
    this.fetchYtEmbed();
    setTimeout(() => {
      this.$defineSlots(this.ads.ads_1);
      this.$defineSlots(this.ads.ads_2);
      this.setOwnedCommunity();
    }, 1000);
  },
  methods: {
    checkURLParams() {
      if (this.$route.params.id || this.serverID) {
        this.communityID = this.$route.params.id;
        this.fetchCommunityData();
      }
    },
    async fetchCommunityData(On) {
      const data = {
        param_id: this.communityID,
        param_slug: "",
      };
      await this.$store
        .dispatch("public/getCommunityDetail", data)
        .then((res) => {
          this.$store.commit("auth/SET_MEMBER", res.member_data);
          this.$store.commit("community/SET_COMMUNITY_PROFILE", res);
          this.communityUserDetailData = res;
          //  define Ads Slot
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    objectArrayParser(array) {
      return array.map((el) => {
        return el.url;
      });
    },
    async submitJoinCommunity() {
      this.loading.join = true;
      const formData = new FormData();
      formData.append("id_community", this.dataFetch.id_community);
      await this.$store
        .dispatch("community/postSubmitJoinCommunity", formData)
        .then((res) => {
          this.fetchMember();
          this.loading.join = false;
          this.drawerMember = false;
        })
        .catch((err) => {
          this.loading.join = false;
          throw new Error(err);
        });
    },
    notAllowed() {
      this.drawerMember = true;
    },
    fetchYtEmbed() {
      const regExp = /(\?v=|\/\d\/|\/embed\/|\/v\/|\.be\/)([a-zA-Z0-9\-\_]+)/;
      const matches = regExp.exec(this.dataFetch.content);
      if (matches && matches.length > 0) {
        this.ytId = matches[2];
      }
    },
    async fetchNewsRelease() {
      this.loading.release = true;
      await this.$store
        .dispatch("public/getNewsRelease", "release")
        .then((res) => {
          this.loading.release = false;
          this.newsRelease =
            res.news_release.length > 0 ? res.news_release : [];
        })
        .catch(() => {
          this.loading.release = false;
        });
    },
    setOwnedCommunity() {
      if (!this.dataFetch.community_ownership) {
        return;
      }
      this.isOwned =
        Number(this.$store.state.auth.user.myProfile.id) ===
        Number(this.dataFetch.community_ownership.id_user);
    },
    handleDropdown(val, item) {
      switch (val) {
        case "edit":
          this.editQuestion(item);
          break;
        case "delete":
          this.deleteQuestion(item);
          break;
      }
    },
    editQuestion() {
      this.$router.push(
        `/community/${this.$route.params.id}/post?edit=${this.$route.params.qid}`
      );
    },
    async deleteQuestion() {
      await this.$store
        .dispatch("question/deleteQuestion", this.$route.params.qid)
        .then((res) => {
          this.$message({
            message: "Berhasil delete question",
            type: "success",
          });
          setTimeout(() => {
            this.$router
              .push(this.localeLocation(`/community/${res.id_community}`))
              .catch((err) => err);
          }, 1000);
        })
        .catch(() => {
          this.$message({
            message: "Gagal delete question",
            type: "error",
          });
        });
    },
    checkParams() {
      if (this.$route.params.id && this.$route.params.qid) {
        this.fetchDetail();
        this.fetchQuestions();
      }
    },
    async submitLike(id) {
      const formData = new FormData();
      formData.append("id", id);
      await this.$store
        .dispatch("public/submitDiscussionLike", formData)
        .then(() => {
          this.$message({
            message: "Berhasil menambahkan like",
            type: "success",
          });
          this.fetchDetail(true);
        })
        .catch(() => {
          this.$message({
            message: "Gagal menambahkan like ini",
            type: "error",
          });
        });
    },
    async fetchDetail(refetch = false) {
      this.loading.detail = true;
      if (this.$route.query && this.$route.query.slug) {
        this.urlSlug = `?slug=${this.$route.query.slug}`;
      }
      const params = {
        id: this.$route.params.id,
        qid: this.urlSlug
          ? this.$route.params.qid + `?share_by=${this.urlSlug}`
          : this.$route.params.qid,
      };

      if (refetch) {
        await this.$store
          .dispatch("public/getDiscussionDetail", params)
          .then((res) => {
            this.dataFetch = res;
            this.loading.detail = false;
          })
          .catch((err) => {
            this.loading.detail = false;
            throw new Error(err);
          });
      }
      this.loading.detail = false;
    },
    async fetchMember() {
      await this.$store
        .dispatch("question/fetchMember", this.$route.params.id)
        .then((res) => {
          const data = res.body;
          if (!data) {
            return;
          }
          this.isActive = data.is_active;
          this.isMember = data.is_member;
          if (
            this.$route.query &&
            this.$route.query.autojoin &&
            !data.is_member
          ) {
            this.submitJoinCommunity();
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    loadMore() {
      this.answers.offset = this.answers.offset + 1;
      this.fetchQuestions(true);
    },
    async fetchQuestions(add = false) {
      this.loading.answer = true;
      const params = {
        id: this.$route.params.id,
        qid: this.$route.params.qid,
        limit: this.answers.limit,
        offset: this.answers.offset,
      };
      await this.$store
        .dispatch("question/getQuestionsAnswers", params)
        .then((res) => {
          if (add) {
            this.answers.lists.push(...res.body);
          } else {
            this.answers.lists = res.body;
          }
          this.answers.total = res.total_records;
          this.loading.answer = false;
        })
        .catch((err) => {
          this.loading.answer = false;
          throw new Error(err);
        });
    },
    reportQuestion(val) {
      this.report_id = val.id;
      this.active_report = true;
    },
    async fetchAskQuestion() {
      this.loading.topQuestion = true;
      await this.$store
        .dispatch("public/getQuestions", "trending")
        .then((res) => {
          this.topQuestionLists = res ? res.trending_questions : [];
          this.loading.topQuestion = false;
        })
        .catch((err) => {
          this.loading.topQuestion = false;
          throw new Error(err);
        });
    },
    handleShare(val, item) {
      if (!window) {
        return;
      }
      const base = window.location.host;
      const dateNow = new Date().getTime();
      const username = this.$store.state.auth.user.myProfile.slug
        ? this.$store.state.auth.user.myProfile.slug
        : "_" + Math.floor(Math.random() * 10000 + 1);
      const url = `https://${base}/mb/community/${item.id_community}/${item.id}?slug=${username}&_utm=${dateNow}`;
      switch (val) {
        case "fb":
          return window.open(
            "http://www.facebook.com/sharer/sharer.php?u=" +
              url +
              "&_ch=facebook",
            "_blank"
          );
        case "wa":
          return window.open(
            "https://api.whatsapp.com/send?text=" + url + "&_ch=whatsapp",
            "_blank"
          );
        case "tw":
          return window.open(
            "https://twitter.com/share?url=" + url + "&_ch=twitter",
            "_blank"
          );
        case "in":
          return window.open(
            "http://www.linkedin.com/shareArticle?mini=true&url=" +
              url +
              "&_ch=linkedin",
            "_blank"
          );
      }
    },
  },
  head() {
    return {
      title:
        this.dataFetch && this.dataFetch.content
          ? "Community" + " | Modoc"
          : "Modoc",
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content:
            this.dataFetch && this.dataFetch.content
              ? "Community" + " | Modoc"
              : "Modoc",
        },
        { hid: "og:type", property: "og:type", content: "article" },
        { hid: "og:site_name", property: "og:site_name", content: "Modoc" },
        {
          hid: "description",
          name: "description",
          content:
            this.dataFetch && this.dataFetch.content
              ? this.dataFetch.content
              : "",
        },
        {
          hid: "og:description",
          name: "og:description",
          content:
            this.dataFetch && this.dataFetch.content
              ? this.dataFetch.content
              : "",
        },
        {
          hid: "og:url",
          name: "og:url",
          content: process.env.BASE_URL + this.$route.path.substring(1),
        },
        {
          property: "og:image",
          content:
            this.dataFetch && this.dataFetch.image_path
              ? this.dataFetch.image_path
              : "https://static.modocapis.com/kotak/images/default-question.jpg",
        },
      ],
      link: [
        {
          rel: "canonical",
          href:
            this.dataFetch && this.dataFetch.content
              ? `${process.env.BASE_COMMUNITY}mb/community/${this.dataFetch.id_community}/${this.dataFetch.id}`
              : "",
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
      script: [
        {
          async: true,
          src: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
        },
      ],
    };
  },
};
